import { AspectRatio, Box, Container, Heading, Icon, Text } from '@chakra-ui/react';
import { ArrowRightAnimated } from 'components/common/icons/assets/ArrowRightAnimated';
import { Cube } from 'components/common/icons/assets/Cube';
import Image from "next/image";
import { VFC } from 'react';
import { Storyblok } from 'types';

type PodcastHeroProps = {
  title: string;
  text: string;
  platforms: { image: Storyblok.Image, url: string }[];
  image: Storyblok.Image;
};

const PodcastHero: VFC<PodcastHeroProps> = ({
  title,
  text,
  platforms,
  image,
}) => {
  return (
    <Box
      bgGradient="linear(to-br, #a233d8, #8329d1, #6e26d7, #6126e9, #2D9CDB)"
      width="100%"
    >
      <Container
        as="section"
        display="flex"
      >
        {/* Mobile */}
        <Box
          display={{ base: "flex", desktop: "none" }}
          flexDirection="column"
          alignItems="center"
          flex={1}
          gap={3}
        >
          <Box
            display="flex"
            alignItems="center"
            gap={2}
            width="full"
          >
            <Box color="yellow.100">
              <Icon as={Cube} />
            </Box>
            <Text
              textColor="yellow.100"
              size="xs"
            >
              Resources
            </Text>
            <Box color="white">
              <Icon as={ArrowRightAnimated} fontSize="15px" />
            </Box>
            <Text
              textColor="white"
              size="xs"
            >
              Podcast
            </Text>
          </Box>
          <AspectRatio
            ratio={1}
            position="relative"
            width="75%"
            rounded="xl"
            overflow="hidden"
          >
            <Image
              src={image.filename}
              alt={image.alt}
              layout="fill"
              objectFit="contain"
            />
          </AspectRatio>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <Heading
              as="h1"
              size="xl"
              fontWeight="bold"
              textColor="white"
              width="full"
            >
              {title}
            </Heading>
            <Text
              as="h2"
              size="sm"
              textColor="white.70"
            >
              {text}
            </Text>
          </Box>
          <Box width="full" display="flex" flexWrap="wrap" gap={4}>
            {
              platforms.map((platform) => (
                <Image
                  src={platform.image.filename}
                  height={40}
                  width={150}
                  objectFit="contain"
                  alt={platform.image.alt}
                  key={platform.image.filename}
                />
              )
              )
            }
          </Box>
        </Box>
        {/* Desktop */}
        <Box
          display={{ base: "none", desktop: "flex" }}
          alignItems="center"
          width="full"
          gap={16}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={8}
            flex={1}
          >
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              width="full"
            >
              <Box color="yellow.100">
                <Icon as={Cube} />
              </Box>
              <Text
                textColor="yellow.100"
                size="xs"
              >
                Resources
              </Text>
              <Box color="white">
                <Icon as={ArrowRightAnimated} fontSize="15px" />
              </Box>
              <Text
                textColor="white"
                size="xs"
              >
                Podcast
              </Text>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Heading
                as="h1"
                size="xl"
                fontWeight="bold"
                textColor="white"
                width="full"
              >
                {title}
              </Heading>
              <Text
                as="h2"
                size="sm"
                textColor="white.70"
              >
                {text}
              </Text>
            </Box>
            <Box width="full" display="flex" flexWrap="wrap" gap={4}>
              {
                platforms.map((platform) => (
                  <Image
                    src={platform.image.filename}
                    height={40}
                    width={150}
                    objectFit="contain"
                    alt={platform.image.alt}
                    key={platform.image.filename}
                  />
                ))
              }
            </Box>
          </Box>
          <Box
            position="relative"
            height="360px"
            width="360px"
            rounded="xl"
            overflow="hidden"
          >
            <Image
              src={image.filename}
              alt={image.alt}
              layout="fill"
              objectFit="contain"
            />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default PodcastHero;
