import { AspectRatio, Box, Container, Grid, Heading, Text } from "@chakra-ui/react";
import ArticleRichText from "components/common/ArticleRichText";
import CallToActions from "components/common/CallToActions";
import { Triangle } from "components/common/icons/assets/Triangle";
import { HubSpotCTAProps } from "components/common/RichText/HubSpotCTA";
import { ShareButtons } from "components/common/ShareButtons";
import { Summary } from "components/common/Summary";
import { ThreeColumnGrid } from "components/common/ThreeColumnGrid";
import { useAnchorsObserver } from "lib/hooks/useAnchorsObserver";
import { useTranslation } from "lib/hooks/useTranslation";
import Image from "next/image";
import { VFC } from 'react';
import { Storyblok } from "types";
import { formatDate } from "utils/formatDate";


/* AudioPlayer */

type AudioPlayerProps = {
  display,
}

const AudioPlayer: VFC<AudioPlayerProps> = (props: {
  display,
}) => {
  return (
    <Box
      width="full"
      height="48px"
      gap="16px"
      alignItems="center"
      {...props}
    >
      <Box
        bgColor="sapphire.100"
        height="48px"
        width="48px"
        rounded="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexShrink={0}
      >
        <Triangle color="white" />
      </Box>
      <Box
        width="full"
        display="flex"
        flexDirection="column"
        alignItems="end"
        height="full"
      >
        <Text
          as="h5"
          fontSize="small"
          textColor="saphhire.100"
        >
          {"00:41:08"}
        </Text>
        <Box
          width="full"
          height="4px"
          bgColor="sapphire.10"
        />
      </Box>
    </Box>
  );
}

/* PodcastBody */

type PodcastBodyProps = {
  // Podcast
  title: string,
  image: Storyblok.Image,
  time: string,
  description: string,
  content: Storyblok.RichText,
  date: string;
  youtubeVideoId: string,
  // Other
  leftSidebarTitle: string;
  anchorHeadings: { id: string; name: string; }[];
  customAnchorHeadings: { url: string; newName: string; }[];
  rightSidebarCallToActions: HubSpotCTAProps[];
  metadata: Storyblok.Metadata;
};

const PodcastBody: VFC<PodcastBodyProps> = ({
  // Podcast
  title,
  image,
  time,
  description,
  content,
  date,
  youtubeVideoId,
  // Other
  leftSidebarTitle,
  anchorHeadings,
  customAnchorHeadings,
  rightSidebarCallToActions,
  metadata,
}) => {

  const { locale } = useTranslation();

  const OBSERVED_ANCHOR_CLASS_NAME = 'podcast-anchor';
  const currentAnchorId = useAnchorsObserver(OBSERVED_ANCHOR_CLASS_NAME);

  return (
    <Container as="article">
      <ThreeColumnGrid
        center={
          <Heading as="h1" size="2xl">
            {title}
          </Heading>
        }
      />
      <ThreeColumnGrid
        left={
          <Summary
            activeItemId={currentAnchorId}
            title={leftSidebarTitle}
            listItems={anchorHeadings}
            customListItems={customAnchorHeadings}
          />
        }
        center={
          <>
            <AspectRatio
              as="figure"
              width="100%"
              borderRadius="8px"
              overflow="hidden"
              my="32px"
              ratio={16 / 9}
            >
              <Box as="iframe"
                src={`https://www.youtube.com/embed/${youtubeVideoId}`}
                allowFullScreen
                height="full"
                width="full"
                frameBorder="0"
              />
            </AspectRatio>
            <Box
              mb="32px"
              width="full"
              bgColor="sapphire.10"
              borderRadius="8px"
              padding="12px"
              display="flex"
              flexDirection="column"
              gap="32px"
            >
              <Box
                display="flex"
                gap="16px"
                alignItems="center"
              >
                <Box
                  height={{ base: "92px", tablet: "158px" }}
                  width={{ base: "92px", tablet: "158px" }}
                  bgColor="red"
                  position="relative"
                  overflow="hidden"
                  borderTopRadius={{ base: '6px', tablet: "6px" }}
                  borderBottomRadius={{ base: '2px', tablet: "6px" }}
                  flexShrink={0}
                >
                  <Image
                    src={image.filename}
                    alt={image.alt}
                    layout="fill"
                    objectFit="cover"
                    priority
                  />
                </Box>
                <Box
                  width="full"
                  display="flex"
                  flexDirection="column"
                  gap="32px"
                >
                  <Box>
                    <Text
                      as="h3"
                      size="lg"
                      fontWeight="bold"
                      textColor="saphhire.100"
                    >
                      {title}
                    </Text>
                    <Text
                      as="h5"
                      fontSize="small"
                      textColor="saphhire.100"
                    >
                      {formatDate(date, 'PP', locale)}
                    </Text>
                  </Box>
                  <AudioPlayer display={{ base: "none", tablet: "flex" }} />
                </Box>
              </Box>
              <AudioPlayer display={{ base: "flex", tablet: "none" }} />
            </Box>
            <ArticleRichText
              anchorClassName={OBSERVED_ANCHOR_CLASS_NAME}
              content={content}
            />
          </>
        }
        right={
          <Grid gridGap="16px" >
            {rightSidebarCallToActions?.length > 0 && <CallToActions callToActions={rightSidebarCallToActions} />}
            <ShareButtons title={metadata.title} description={metadata.description} />
          </Grid >
        }
      />
    </Container >
  );
};

export default PodcastBody;
